a, a:visited {
    color: var(--text-color);
}

a:hover {
    color: var(--primary-color);
}

.homepage-wrapper, .homepage-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
}

.homepage-box {
    box-shadow: 1px 1px 10px 3px black;
    border-radius: 10px;
    text-align: justify;
}