.header-wrapper {
    background-color: var(--background-color-header-footer);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: var(--text-color);
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid var(--text-color);
    box-shadow: 1px 1px 10px 3px black;
    padding-left: 3rem;
    padding-right: 3rem;
}

.header {
  height: 100%;
  object-fit: contain;
  margin-right: auto;
}

.header span {
  font-weight: 600;
}

/* Nav Menu and Burger Menu */

.navbar {
  background-color: var(--background-color);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  box-shadow: 1px 1px 5px 3px black;
}

.nav-links {
  list-style: none;
  display: none;
  margin: 0;  
  position: absolute;
  top: 50px;
  right: 0;
  background-color: var(--background-color-header-footer);
  flex-direction: column;
  width: 200px;
  transition: transform 0.3s ease-in-out;
  border-radius: 15px;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 0 10px 5px black;
  z-index: 100;
}

.nav-links li {
  margin: 0 10px;
}

.nav-links a {
  text-decoration: none;
  display: block;
  border-radius: 15px;
  padding: 10px;
}

.nav-links a:hover {
  background-color: var(--background-color);
  color: var(--text-color);
}

.nav-links.active {
  display: flex;  
  transform: translate(-1rem, 2rem);
}

.theme-toggle-button {
  text-align: left; 
  background-color: var(--background-color-header-footer);
  color: var(--text-color);
  margin: 0 5px;
  border: none;
  border-radius: 15px;
  line-height: 1.5;
  font-size: calc(10px + 1.65vmin);
}

.theme-toggle-button:hover {
  background-color: var(--background-color);
}

.burger-menu {
    display: flex;
}

.burger-menu {
  flex-direction: column;
  cursor: pointer;
}

.burger-menu .line {
  width: 25px;
  height: 3px;
  background-color: var(--text-color);
  margin: 4px 0;
}