/* Example Box and Wrapper */

.exampleBox {
  display: flex;
  height: auto;
  min-height: 275px;
  width: 250px;
  box-shadow: 1px 1px 10px 3px black;
  border-radius: 10px;
  justify-content: center;
  flex-direction: column;
  flex: 1 1 calc(50% - 20px); /* 2 columns */
}

@media (min-width: 800px) {
  .exampleBox {
    flex: 1 1 calc(30% - 20px); /* 3 columns */
  }
}


/* Transforming Button */

.transforming-button {
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 24px;
    transition: width 0.3s ease, border-radius 0.3s ease;
}
  
.transforming-button:hover {
  width: 150px;
  border-radius: 25px;
  background-color: var(--primary-color-hover);
  font-size: 20px;
}

.transforming-button span {
  display: inline-block;
  transition: opacity 1s ease;
}

.transforming-button span.transformed-button {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.transforming-button:hover span.initial-button,
.transforming-button:not(:hover) span.transformed-button {
  opacity: 0;
  visibility: hidden;
}

.transforming-button:hover span.transformed-button {
  opacity: 1;
  visibility: visible;
}


/* Holding Button */

.holding-button {
  cursor: pointer;
  transition: background-color 2.0s, transform 0.3s;
}

.holding-button:active {
  transform: scale(0.95); /* Small scale animation on click */
  background-color: var(--background-color);
}

.holding-button-progress {
  cursor: pointer;
}


/* Progress Bar */

.progress-bar {
  width: 100%;
  background-color: var(--background-color);
  border: 1px solid var(--background-color);
  height: 5px;
  margin-top: 10px;
}

.progress {
  height: 100%;
  background-color: #4caf50; /* Green background */
  transition: width 0.2s;
}


/* Shine */

.shining-button {
  color: var(--text-color);
  background-color: var(--primary-color);
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.shining-button::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 30%;
  height: 200%;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.2) 75%);
  transform: skewX(-30deg);
  animation: shine 3s infinite; /* Duration is 3s to include delay */
  animation-timing-function: linear;
}

@keyframes shine {
  0% {
      left: -100%;
  }
  16.33% {
      left: 120%;
  }
  100% {
      left: 120%;
  }
}