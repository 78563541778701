/* Example Box and Wrapper */
  
.exampleBoxImage {
    display: flex;
    height: auto;
    min-height: 375px;
    width: 425px;
    box-shadow: 1px 1px 10px 3px black;
    border-radius: 10px;
    justify-content: center;
    flex-direction: column;
    flex: 1 1 calc(50% - 20px);
}

.exampleBoxImage img {
    border-radius: 2rem;
    height: 200px;
    width: auto;
    padding: 1rem;
}

@media (max-width: 600px) {
    .exampleBoxImage {
        width: 100%;
    }
}


/* Colour Change Hover */

.colour-change:hover {
    animation: pixelate-grey 3s ease-in-out 0s 1 forwards;
}

@keyframes pixelate-grey {
    0% { filter: none; }
    50% { filter: blur(10px) grayscale(20%)}
    100% { filter: blur(0px) grayscale(100%)}
}


/* Spoiler Image */

.spoiler-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.spoiler-cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    text-align: center;
    z-index: 1;
    border-radius: 2rem;
    transform: scale(0.975);
}

.spoiler-image {
    display: block;
    width: 100%;
    height: auto;
}

.spoiler-container:not(.revealed) .spoiler-image {
    filter: blur(10px);
}

.spoiler-container.revealed .spoiler-cover {
    display: none;
}

.greyscale {
    filter: grayscale(100%);
}


/* Transforming Image */

.image-transformer-container {
    position: relative;
    width: 300px;
    height: 200px;
}

.image-transformer-container .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 2s ease;
}

.image-transformer-container .transformer {
    z-index: 1;
}

.image-transformer-container:hover .transformer {
    animation: pixelate 2s forwards;
}

.image-transformer-container .transformed {
    opacity: 0;
    z-index: 2;
    transition: opacity 2s ease;
}

.image-transformer-container:hover .transformed {
    opacity: 1;
}

@keyframes pixelate {
    0% { filter: none; }
    50% { filter: blur(10px) grayscale(50%) scale(0.9);}
    100% { filter: blur(0px)}
}


/* Spoiler Hover */

.spoiler-hover {
    filter: blur(20px);
}

.spoiler-hover:hover {
    filter: none;
    transition: 1s ease-in;
}