:root {
  --background-color: #282c34;
  --background-color-header-footer: #1b1e24;
  --text-color: #ffffff;
  --primary-color: #007bff;
  --primary-color-hover: #0056b3;
}

[data-theme="light"] {
  --background-color: #e2e2e2;
  --background-color-header-footer: #cacaca;
  --text-color: #000000;
  --primary-color: #007bff;
  --primary-color-hover: #2787ec;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: var(--text-color);
  padding: 20px;
  transition: background-color 0.3s, color 0.3s;
}

.button {
  border-radius: 25px;
  background-color: var(--primary-color);
  color: var(--text-color);
  line-height: 50px;
  font-family: Arial, sans-serif;
  padding: 0;
  border: none;
  justify-content: center;
  font-size: 24px;
  width: 150px;
  margin: 5px;
}

.small-button {
  border-radius: 15px;
  background-color: var(--primary-color);
  color: var(--text-color);
  line-height: 20px;
  font-family: Arial, sans-serif;
  padding: 5px;
  border: none;
  justify-content: center;
  font-size: 16px;
  width: auto;
  margin: 5px;
}

.button:hover {
  background-color: var(--primary-color-hover);
}

.flex {
  display: flex;
}

.full-center {
  justify-content: center;
  align-items: center;
}

.wrap {
  flex-wrap: wrap;
}

.gap-05 {
  gap: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

input {
  padding: 10px;
  border-radius: 10px;
  margin: 5px;
}

.blerb-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px;
  box-shadow: 1px 1px 10px 3px black;
  border-radius: 10px;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.wrapper {
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap; /* Allows items to wrap into multiple lines */
  box-sizing: border-box;
}