/* Example Box and Wrapper */

.todo-input-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.todo-box {
    box-shadow: 1px 1px 10px 3px black;
    border-radius: 10px;
    padding: 1rem;
}

.todo-list {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    gap: 1rem;
}

.todo-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    border-radius: 15px;
    padding-left: 15px;
    font-size: 1rem;
}

.todo-item div {
    flex: 14;
}

.completed-item {
    background-color: rgba(0, 107, 0, 0.3);
}

.completed-item div {
    text-decoration: line-through;
}

.remove-todo-button {
    line-height: 30px;
    font-size: 1rem;
    max-width: 30px;
    min-width: 30px;
    margin: 5px;
    background-color: red;
    flex: 1
}

.complete-todo-button {
    line-height: 30px;
    font-size: 1rem;
    width: 80px;
    min-width: 80px;
    margin: 5px;
    flex: 2;
}