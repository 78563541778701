/* General */

.game-select-wrapper{
  box-shadow: 1px 1px 10px 3px black;
  border-radius: 10px;
  padding: 10px;
}

.game-wrapper {
  display: flex;
  justify-content: center; 
  flex-wrap: wrap; 
  box-shadow: 1px 1px 10px 3px black;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  margin-top: 30px;
}

.game-title{
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  margin: 0.33em;
  min-width: 400px;
}

@media (max-width: 600px) {
  .game-title{
    min-width: 200px;
  }
}


/* Player Display, Score, Lives */

.player-display {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-basis: 100%;
}

.lives-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.life-icon {
  width: 30px;
  height: 30px;
  margin: 0 5px;
}


/* Question */

.question-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-basis: 75%;
  box-shadow: 1px 1px 10px 3px black;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.question-container-part {
  display: flex;
  flex-wrap: wrap;
  flex: 1; 
  max-width: 45%; 
  min-width: 20%; 
  gap: 10px; 
  justify-content: center; 
}

.question-container-operator {
  flex: 0 0 10%;
  text-align: center;
}

.question-image {
  width: 64px;
  height: auto;
  margin: 0 2px;
  flex-basis: 33%;
  border-radius: 10px;
}

.question-addition-sign {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0 5px;
  font-size: 3rem;
  width: 60px;
  height: 60px; 
}

.prompt-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 700px) {
  .question-container {
    flex-direction: column;
    flex-basis: 95%;
  }

  .question-container-part {
    max-width: none;
    flex-wrap: nowrap;
    gap: 5px;
  }

  .question-image {
    width: 48px;
  }

  .question-container-operator {
    flex: 0 0 auto;
  }
}


/* Answers */

.answers-container {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  justify-content: center;
  gap: 20px;
  max-width: 95%;
  margin: 20px auto;
}

.answer-card {
  padding: 1rem 1rem;
  cursor: pointer;
  font-size: 3rem;
  border-radius: 1rem;
  flex: 1 1 calc(50% - 10px);
  text-align: center;
  transition: background 0.3s ease;
}

.answer-card:hover {
  background-color: var(--primary-color);
}

.answer-card:disabled {
  background-color: #f44336;
  color: #fff;
  pointer-events: none;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .answers-container {
    flex-basis: 75%;
  }
}

.notification {
  font-weight: bold;
  color: #d32f2f;
  text-align: center;
  margin: 10px;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.notification.hide {
  opacity: 0;
  transform: scale(0.9);
  pointer-events: none;
}