/* Example Box and Wrapper */
.exampleBoxPost {
    display: flex;
    height: auto;
    min-height: 300px;
    width: 425px;
    box-shadow: 1px 1px 10px 3px black;
    border-radius: 10px;
    justify-content: center;
    flex-direction: column;
    flex: 1 1 calc(50% - 20px); /* 2 columns */
}

@media (max-width: 600px) {
    .exampleBoxPost {
        width: 60vw;
    }

    .exampleBoxPost h4 {
        font-size: 1.5rem;
    }

    .exampleBoxPost p {
        font-size: 0.8rem;
    }
}

.exampleBoxPost h4 {
    max-width: 90%;
    font-size: 1.65rem;
}

.shown {
    overflow: visible;
    white-space: normal;
}

.hidden {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

.exampleBoxPost p {
    font-size: 1rem;
    max-width: 90%;
    text-align: justify;
}

.post-title {
    cursor: pointer;
}